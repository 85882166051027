<template>
  <topNav />
  <div class="legendContainer">
    <div class="legends" v-for="legend in allLegends" :key="legend.id">
      <div class="legendTitle">{{ legend.name }}</div>
      <div class="legendItems">
        <div
          class="legendItem"
          v-for="item in legend.items"
          :key="item.name"
        >
          <img
            class="legendImg"
            :src="getImagePath(item.image)"
            :alt="item.name"
          />
          <div class="legendName">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topNav from '@/ww25/components/pdpTopNav.vue';

export default {
  name: 'Legends',
  components: {
    topNav,
  },
  data() {
    return {
      langParam: this.$route.params.lang,
      categoryData: {},
      region: '',
      categorySlug: this.$route.params.categories,
      allLegends: [],
      singleChapter: null,
      singleProduct: null,
    };
  },
  computed: {
    isDataLoaded() {
      return this.categoryData !== null;
    },
  },
  async created() {
    await this.initializeData();
    this.pushPageViewToDataLayer();
  },
  methods: {
    async initializeData() {
      try {
        const categoriesDataModule = await import(
          /* webpackChunkName: "categories-data-[request]" */
          `@/ww25/data/${this.langParam}/categories.json`
        );
        const categoriesData = categoriesDataModule.default || categoriesDataModule;
        this.region = categoriesData.region || '';


        // Access the categories array
        const categoriesArray = categoriesData.categories;

        if (Array.isArray(categoriesArray)) {
          this.categoryData = categoriesArray.find(
            (category) => category.slug === this.categorySlug
          );

          if (this.categoryData) {
            this.allLegends = this.categoryData.legends || [];
            this.singleChapter = this.categoryData.chapters?.[0] || null;
            this.singleProduct = this.singleChapter?.products?.[0] || null;
          } else {
            console.error(
              `Category '${this.categorySlug}' not found in categories.json for language '${this.langParam}'.`
            );
          }
        } else {
          console.error('categoriesData.categories is not an array.');
        }
      } catch (error) {
        console.error('Error loading categories.json:', error);
      }
    },
    getImagePath(image) {
      try {
        return require(`@/ww25/assets/images/features/${image}.svg`);
      } catch (error) {
        console.error(`Image not found: ${image}.svg`, error);
        return '';
      }
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    pushPageViewToDataLayer() {
      window.dataLayer?.push({
        region: this.region,
        locale: this.langParam,
        site: 'books',
        division: 'wfj',
        page_type: 'legend',
        page_name: 'legend',
        sku: this.$route.params.productRef || '',
        collection: this.removeAccents(this.singleChapter.collection),
        category: this.removeAccents(this.categoryData.tracking || this.categoryData.slug),
        sub_category_level1: 'pre event 2025',
        event: 'pageview',
      });
    },
  },
};
</script>