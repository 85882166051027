<template>
  <div>
    <topNav />
    <div class="product" v-if="singleProduct">
      <div
        class="singleProductImage"
        :class="dynamicClasses"
      >
        <div class="productImageContainer">
          <!-- Single Image without Video -->
          <img
            v-if="singleProduct.oneImg && !singleProduct.video"
            rel="preload"
            :class="{ isOn: !isOn }"
            :src="getProductImagePath(singleProduct.ref)"
          />

          <!-- Second Image without Video -->
          <img
            v-if="singleProduct.secondImg && !singleProduct.video"
            style="display: none"
            :class="{ isOn: !isOn }"
            :src="getProductImagePath(singleProduct.ref)"
          />

          <!-- Second Image with Video -->
          <img
            v-if="singleProduct.secondImg && singleProduct.video"
            style="display: none"
            :class="{ isOn: !isOn }"
            :src="getProductImagePath(singleProduct.ref)"
          />

          <!-- Second Image Toggle -->
          <img
            v-if="singleProduct.secondImg && !singleProduct.video"
            style="display: none"
            :class="{ isOn: isOn }"
            rel="preload"
            :src="getProductImagePath(singleProduct.ref, '-2')"
          />

          <!-- Video Player -->
          <div
            v-if="singleProduct.video && singleProduct.secondImg"
            style="display: none"
            :class="{ isOn: isOn }"
            class="videoPlayer"
          >
            <video playsinline autoplay muted loop>
              <source
                :src="getProductVideoPath(singleProduct.ref)"
                type="video/mp4"
              />
            </video>
          </div>

          <!-- Slider -->
          <div
            class="slider"
            v-if="singleProduct.hasSlideShow"
            :class="{ isOn: isOn }"
          >
            <vueper-slides class="no-shadow"
              :arrows="true" :fixed-height="false"
              @slide="handlePaginationClick"
              @previous="handleArrowClick('previous')"
              @next="handleArrowClick('next')"             
            >
              <vueper-slide
                v-for="(slide, i) in sliderList"
                :key="i"
                :image="getProductImagePath(slide.img)"
              />
            </vueper-slides>
          </div>

          <!-- Hidden Feature Toggle -->
          <div
            class="hiddenFeature"
            v-if="singleProduct.secondImg"
          >
            <a
              class="hiddenFeatureCta"
              @click="featureClick"
            >
              <svg style="display:none" v-bind:class="{isOn: !isOn }" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#1D1D1D"/>
                <path d="M19 27V27.25H19.25H20.75H21V27V21H27H27.25V20.75V19.25V19H27H21V13V12.75H20.75H19.25H19V13V19H13H12.75V19.25V20.75V21H13H19V27Z" fill="#F9F9F9" stroke="#F9F9F9" stroke-width="0.5"/>
              </svg>
              <svg v-if="isBlack == true" style="display:none" v-bind:class="{isOn: isOn }" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#F9F9F9"/>
              <line x1="14" y1="19.75" x2="26" y2="19.75" stroke="#1D1D1D" stroke-width="2.5"/>
              </svg>
              <svg v-else style="display:none" v-bind:class="{isOn: isOn }" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#1D1D1D"/>
              <line x1="14" y1="19.75" x2="26" y2="19.75" stroke="#F9F9F9" stroke-width="2.5"/>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <!-- Product Information -->
      <div class="productInfos">
        <div
          class="singleProductTitle"
          v-html="singleProduct.name"
        ></div>

        <!-- Product Reference -->
        <div
          class="productRef"
          v-if="singleCat.atrium"
        >
          {{ singleProduct.ref }}{{ singleProduct.extraRef }}
        </div>
        <div
          class="productRef"
          v-else
        >
          {{ singleProduct.ref }}{{ singleProduct.extraRef }} - {{ langData.launchDate }}: {{ singleProduct.date }}
        </div>

        <div
          class="productPrice"
          v-if="!singleCat.atrium && (singleProduct.estimPrice || singleProduct.recoPrice)"
        >
          <template v-for="price in allPricesForCurrentProduct" :key="price.lang">
            <span>
              {{ price.localPrice }}
            </span>
            <!-- Optionally, add a separator if needed -->
            <span v-if="!isLastPrice(price)"> | </span>
          </template>
          <span>
            ({{ singleProduct.estimPrice ? langData.estimPrice : langData.recoPrice }})
          </span>
        </div>

        <!-- Product Features -->
        <div class="productFeatures">
          <img
            v-for="feature in productFeatures"
            :key="feature"
            :src="getFeatureImagePath(feature)"
          />
        </div>

        <!-- Product Description -->
        <div class="singleProductDesc">
          <div class="productSubDesc">
            <div
              v-for="attribute in productAttributes"
              :key="attribute.key"
              class="productAttribute"
            >
              <span>{{ attribute.label }}</span><br />
              <div v-html="attribute.value"></div>
            </div>
            <div class="productLegals">
              {{ singleProduct.mentions }}<br />
              {{ singleProduct.secondMentions }}
            </div>
            <div class="productLegals">
              {{ singleProduct.hashtags }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Indicator -->
    <div v-else>
      <p>Loading product details...</p>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import topNav from '@/ww25/components/pdpTopNav.vue';
import { loadCategoryData, loadProductData } from '@/ww25/utils/dataLoader';
import pricesData from '@/ww25/data/prices.json';

export default {
  components: {
    topNav,
    VueperSlides,
    VueperSlide,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    categories: {
      type: String,
      required: true,
    },
    chapterName: {
      type: String,
      required: true,
    },
    productRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      langData: {}, // Language data loaded from categories.json
      langParam: this.$route.params.lang,
      categoryData: {},
      region: '',
      categorySlug: this.$route.params.categories,
      singleCat: null,
      singleChapter: null,
      singleProduct: null,
      sliderList: [],
      isOn: false,
      isBlack: false,
      isWhite: false,
      isArrowClick: false,
    };
  },
  async created() {
    await this.getDetails();
    this.pushPageViewToDataLayer()
  },
  computed: {
    dynamicClasses() {
      return {
        isBlack: this.isBlack,
        isWhite: this.isWhite && this.singleProduct?.isWhite !== false, // Exclude `isWhite` if explicitly false
      };
    },
    productFeatures() {
      // Collect all feature flags that are true
      if (!this.singleProduct) return [];
      const features = [];
      const featureList = [
        'limited',
        'unique',
        'box',
        'diameter55',
        'diameter42',
        'diameter38',
        'diameter33',
        'diameter29',
        'diameter28',
        'diameter25',
        'diameter19',
        'diameter12',
        'diameter10',
        'height34',
        'height19',
        'height27',
        'height37',
        'height39',
        'ceramic',
        'size1',
        'size64',
        'sizeXsL',
        'sizeSM',
        'sizeSXL',
        'sizeXssM',
        'sizeXsXL',
        'yellow18',
        'white18',
        'beige18',
        'pink18',
        'enamel',
        'steel',
        'sapphire',
        'diamonds',
        'jade',
        'sapphirestone',
        'titanium',
        'onyx',
        'platine',
        'pvd',
        'gia',
        'triplebuckle',
        'ardillon',
        'bouton',
        'uclasp',
        'clasp',
        'movement72',
        'movement70',
        'movement8d',
        'movement42',
        'movement50',
        'movement55',
        'movementA50',
        'quartz',
        'resistant200',
        'resistant50',
        'resistant30',
        'mti',
        'pair',
        'single',
        'transformable',
        'reverse'
      ];
      featureList.forEach((feature) => {
        if (this.singleProduct[feature]) {
          features.push(feature);
        }
      });
      return features;
    },
    productAttributes() {
      if (!this.singleProduct) return [];
      const attributes = [];
      const attributeList = [
        { key: 'hasCase', label: this.langData.case, value: this.singleProduct.case },
        { key: 'hasCaseBack', label: this.langData.caseBack, value: this.singleProduct.caseback },
        { key: 'hasBezel', label: this.langData.bezel, value: this.singleProduct.bezel },
        { key: 'hasDial', label: this.langData.dial, value: this.singleProduct.dial },
        { key: 'hasCrown', label: this.langData.crown, value: this.singleProduct.crown },
        { key: 'hasChain', label: this.langData.chain, value: this.singleProduct.chain },
        { key: 'hasHands', label: this.langData.hands, value: this.singleProduct.hands },
        { key: 'hasBracelet', label: this.langData.bracelet, value: this.singleProduct.bracelet },
        { key: 'hasGalonSpecificities', label: this.langData.specificities, value: this.singleProduct.specificities },
        { key: 'hasMovement', label: this.langData.movement, value: this.singleProduct.movement },
        { key: 'hasReserve', label: this.langData.reserve, value: this.singleProduct.reserve },
        { key: 'hasDimensions', label: this.langData.dimensions, value: this.singleProduct.dimensions },
        { key: 'hasWidth', label: this.langData.width, value: this.singleProduct.width },
        { key: 'hasFunctions', label: this.langData.functions, value: this.singleProduct.functions },
        { key: 'hasDiamonds', label: this.langData.diamonds, value: this.singleProduct.diamondStones },
        { key: 'hasSingleDiamond', label: this.langData.singleDiamond, value: this.singleProduct.diamondStones },
        { key: 'hasSapphires', label: this.langData.sapphires, value: this.singleProduct.sapphires },
        { key: 'hasDescription', label: '', value: this.singleProduct.description },
        { key: 'hasSizes', label: this.langData.sizes, value: this.singleProduct.sizes },
        { key: 'hasMaterials', label: this.langData.materials, value: this.singleProduct.materials },
        { key: 'hasSpecificities', label: this.langData.specificities, value: this.singleProduct.specificities },
        { key: 'hasFastening', label: this.langData.fastening, value: this.singleProduct.fastening },
        { key: 'hasGoldWeight', label: this.langData.goldWeight, value: this.singleProduct.goldWeight },
      ];
      
      attributeList.forEach((attr) => {
        if (this.singleProduct[attr.key]) {
          attributes.push({
            key: attr.key,
            label: attr.label,
            value: attr.value,
          });
        }
      });
      return attributes;
    },
    localizedSingleProductPrice() {
    // Find the localized price for the specific product
    const productPrice = this.localizedProductPrices.find(
      (product) => product.ref === this.productRef
    );
    // Return the found price or an empty object with defaults if not found
    return productPrice || { localPrice: '', currency: '' };
  },

  localizedProductPrices() {
    const lang = this.lang; // get the current lang parameter
    return pricesData.products.map((product) => {
      const localizedPrice = product.prices[lang];
      return {
        ref: product.ref,
        name: product.name,
        currency: localizedPrice?.currency || '',
        localPrice: localizedPrice?.localPrice || '',
      };
    });
  },
    shouldShowFeatureText() {
      return (
        (this.isBlack || this.isWhite) &&
        !this.singleProduct.video &&
        !this.singleProduct.noFocus
      );
    },
    allPricesForCurrentProduct() {
      const product = pricesData.products.find(
        (product) => product.ref === this.productRef
      );

      if (!product || !product.prices) return [];

      return Object.entries(product.prices).map(([langKey, priceObj]) => {
        return {
          lang: langKey,
          localPrice: priceObj.localPrice || '',
          currency: priceObj.currency || '',
        };
      });
    }
  },
  methods: {
    async getDetails() {
      try {
        // Load language data
        const categoryData = await loadCategoryData(this.langParam);
        this.langData = categoryData;
        this.region = categoryData.region || '';

        this.categoryData = categoryData.categories.find(
          (category) => category.slug === this.categorySlug
        );

        if (this.categoryData) {
          this.chapters = this.categoryData.chapters || [];
        } else {
          console.error(`Category ${this.categorySlug} not found.`);
        }

        // Find the category
        this.singleCat = categoryData.categories.find(
          (category) => category.slug === this.categories
        );
        if (!this.singleCat) {
          console.error(`Category '${this.categories}' not found.`);
          return;
        }

        // Find the chapter
        this.singleChapter = this.singleCat.chapters.find(
          (chapter) => chapter.slug === this.chapterName
        );
        if (!this.singleChapter) {
          console.error(`Chapter '${this.chapterName}' not found.`);
          return;
        }

        // Load product data from individual JSON file
        const productData = await loadProductData(this.lang, this.productRef);
        if (!productData) {
          console.error(`Product data for '${this.productRef}' not found.`);
          return;
        }
        this.singleProduct = productData;

        // Set slider list if available
        this.sliderList = this.singleProduct.sliderList || [];
      } catch (error) {
        console.error('Error in getDetails:', error);
      }
    },
    isLastPrice(price) {
      const lastPrice = this.allPricesForCurrentProduct[this.allPricesForCurrentProduct.length - 1];
      return price.lang === lastPrice.lang;
    },
    featureClick() {
      this.handleFeatureButtonClick();
      this.toggleFeature();
    },
    toggleFeature() {
      this.isOn = !this.isOn;
      this.isBlack = !this.isBlack;
      this.isWhite = !this.isWhite;
    },
    getProductImagePath(ref, suffix = '') {
      if (!ref) {
        console.warn('getProductImagePath called with undefined ref');
        return '';
      }
      try {
        return require(`@/ww25/assets/images/products/${ref}${suffix}.png`);
      } catch (error) {
        console.error(`Product image not found: ${ref}${suffix}.png`, error);
        return '';
      }
    },
    getProductVideoPath(ref) {
      if (!ref) {
        console.warn('getProductVideoPath called with undefined ref');
        return '';
      }
      try {
        return require(`@/ww25/assets/images/products/${ref}.mp4`);
      } catch (error) {
        console.error(`Product video not found: ${ref}.mp4`, error);
        return '';
      }
    },
    getFeatureImagePath(feature) {
      try {
        return require(`@/ww25/assets/images/features/${feature}.svg`);
      } catch (error) {
        console.error(`Feature image not found: ${feature}.svg`, error);
        return '';
      }
    },
    handleFeatureButtonClick() {
      window.dataLayer?.push({
        eventcategory: 'image',
        eventaction: this.isOn ? 'minus' : 'plus', // Use isOn state to determine action
        eventlabel: this.productRef || 'unknown', // Use productRef from route params
        event: 'uaevent',
      });
    },
    handleArrowClick() {
      this.isArrowClick = true;
      window.dataLayer?.push({
        eventcategory: 'image',
        eventaction: 'swipe', // Use isOn state to determine action
        eventlabel: this.productRef || 'unknown', // Use productRef from route params
        event: 'uaevent',
      });
    },
    handlePaginationClick() {
      if (this.isArrowClick) {
        this.isArrowClick = false;
        return;
      }

      // Debounce the pagination click to prevent double firing
      if (this.paginationTimeout) {
        clearTimeout(this.paginationTimeout);
      }
      
      this.paginationTimeout = setTimeout(() => {
        window.dataLayer?.push({
          eventcategory: 'image',
          eventaction: 'pagination',
          eventlabel: this.productRef || 'unknown',
          event: 'uaevent',
        });
      }, 100); // Small delay to prevent double firing
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    pushPageViewToDataLayer() {
      window.dataLayer?.push({
        region: this.region,
        locale: this.langParam,
        site: 'books',
        division: 'wfj',
        page_type: 'pdp',
        page_name: this.removeAccents(this.singleProduct.tracking || this.singleProduct.name),
        sku: this.singleProduct.ref,
        collection: this.removeAccents(this.singleChapter.collection),
        category: this.removeAccents(this.categoryData.tracking || this.categoryData.slug),
        sub_category_level1 : 'pre event 2025',
        event: 'pageview',
      });
    },
  },
};
</script>