<template>
  <top-nav />
  <div class="mainContainer">
    <div class="homeContainer">
      <router-link
        v-for="category in categories"
        :key="category.id"
        class="divisionTile"
        :to="{ name: 'Category', params: { lang: langParam, categories: category.slug } }"
        @click="handleCategoryClick(category)"
      >
        <div :class="category.Shadow" class="divisionTitle">
          {{ category.name }}
        </div>
        <img
          rel="preload"
          :class="category.imgPosition"
          :src="getImagePath(category.slug)"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import topNav from '@/ww25/components/topNav.vue';
import { loadCategoryData } from '@/ww25/utils/dataLoader';

export default {
  name: 'Home',
  props: {
    lang: String,
  },
  components: {
    topNav,
  },
  data() {
    return {
      categories: [],
      region: '',
      langParam: this.$route.params.lang || 'en-US',
    };
  },
  async created() {
    try {
      const categoryData = await loadCategoryData(this.langParam);
      
      if (categoryData && typeof categoryData === 'object') {
        this.categories = Array.isArray(categoryData.categories) ? categoryData.categories : [];
        this.region = typeof categoryData.region === 'string' ? categoryData.region : '';
      } else {
        console.error('Invalid category data received:', categoryData);
        this.categories = [];
        this.region = '';
      }

      this.scrollToTop();
      this.pushPageViewToDataLayer();
    } catch (error) {
      console.error('Error loading category data:', error);
      this.categories = [];
      this.region = '';
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getImagePath(img) {
      if (!img) {
        console.warn('No image slug provided');
        return '';
      }
      try {
        return require(`@/ww25/assets/images/divisions/${img}.jpg`);
      } catch (error) {
        console.error(`Error loading image for ${img}:`, error);
        return '';
      }
    },
    handleCategoryClick(category) {
      if (!category) return;
      
      window.dataLayer?.push({
        eventcategory: 'discover category',
        eventaction: 'click',
        eventlabel: category.tracking || category.slug,
        event: 'uaevent',
      });
    },
    pushPageViewToDataLayer() {
      window.dataLayer?.push({
        region: this.region,
        locale: this.langParam,
        division: 'wfj',
        site: 'books',
        page_type: 'homepage',
        page_name: 'homepage',
        category: 'watches and fine jewelry',
        sub_category_level1: 'pre event 2025',
        event: 'pageview',
      });
    },
  },
};
</script>