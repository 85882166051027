export async function loadCategoryData(lang) {
    try {
      const data = await import(`@/ww25/data/${lang}/categories.json`);
      return data.default || data;
    } catch (error) {
      console.error(`Error loading category data for language: ${lang}`, error);
      return {};
    }
  }
  
  export async function loadProductData(lang, productRef) {
    try {
      const data = await import(`@/ww25/data/${lang}/products/${productRef}.json`);
      return data.default || data;
    } catch (error) {
      console.error(`Error loading product data for productRef: ${productRef}`, error);
      return {};
    }
  }