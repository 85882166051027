<template>
  <div>
    <top-nav />
    <div class="mainContainer">
      <!-- Atrium Cover -->
      <div v-if="singleCat.atrium" class="atriumCover">
        <div
          class="pressCollectionTitle"
          :class="[singleChapter.shadow, singleChapter.collectionTextColor]"
        >
          <span v-if="singleCat.isAtrium">{{ langData.display }} {{ singleChapter.id }}</span>
          <br />
          <div v-html="singleChapter.name"></div>
          <br />
          <span v-html="singleChapter.hashtags"></span>
        </div>
      </div>

      <!-- Collection Cover -->
      <div v-else-if="singleChapter && singleChapter.slug" class="HHCollectionCover">
        <img
          :class="singleChapter.imgPosition"
          rel="preload"
          :src="getCollectionImagePath(singleChapter.slug)"
        />
        <div
          class="collectionTitle"
          :class="[singleChapter.shadow, singleChapter.collectionTextColor]"
        >
          <div v-html="singleChapter.name"></div>
        </div>
      </div>

      <!-- Chapter Description -->
      <div v-if="!singleCat.atrium" class="chapterDescription">
        <div v-html="singleChapter.description"></div>
      </div>

      <!-- Products -->
      <div class="products">
        <router-link
          class="productTile"
          v-for="product in collectionContent"
          :key="product.id"
          :to="{
            name: 'SingleProduct',
            params: {
              lang,
              categories,
              chapterName,
              productRef: product.ref,
            },
          }"
          @click="handleProductClick(product)"
        >
          <div class="productImage">
            <img rel="preload" :src="getProductImagePath(product.ref)" />
          </div>
          <div class="productTitle" v-html="product.name"></div>
          <div class="productType" v-html="product.type"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import topNav from '@/ww25/components/topNav.vue';
import { loadCategoryData, loadProductData } from '@/ww25/utils/dataLoader';

export default {
  components: {
    topNav,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    categories: {
      type: String,
      required: true,
    },
    chapterName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      langData: {},
      langParam: this.$route.params.lang,
      categoryData: {},
      region: '',
      categorySlug: this.$route.params.categories,
      singleCat: {},
      singleChapter: {},
      collectionContent: [],
    };
  },
  async created() {
    await this.getDetails();
    this.scrollToTop();
    this.pushPageViewToDataLayer()
  },
  methods: {
    async getDetails() {
      try {
        const categoryData = await loadCategoryData(this.langParam);
        this.langData = categoryData;
        this.region = categoryData.region || '';

        this.categoryData = categoryData.categories.find(
          (category) => category.slug === this.categorySlug
        );

        if (this.categoryData) {
          this.chapters = this.categoryData.chapters || [];
        } else {
          console.error(`Category ${this.categorySlug} not found.`);
        }

        this.singleCat = categoryData.categories.find(
          (category) => category.slug === this.categories
        );

        if (this.singleCat) {
          this.singleChapter = this.singleCat.chapters.find(
            (chapter) => chapter.slug === this.chapterName
          );

          if (this.singleChapter) {
            const products = this.singleChapter.products || [];

            // Load full product data for each product
            this.collectionContent = [];

            for (const product of products) {
              if (product.id) {
                // Load full product data from individual JSON file
                const productData = await loadProductData(this.lang, product.ref);
                if (productData) {
                  this.collectionContent.push(productData);
                } else {
                  console.error(`Product data for '${product.ref}' not found.`);
                }
              }
            }
          } else {
            console.error(`Chapter '${this.chapterName}' not found.`);
          }
        } else {
          console.error(`Category '${this.categories}' not found.`);
        }
      } catch (error) {
        console.error('Error in getDetails:', error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getCollectionImagePath(img) {
      try {
        return require(`@/ww25/assets/images/collections/${img}.jpg`);
      } catch (error) {
        console.error(`Collection image not found: ${img}.jpg`, error);
        return '';
      }
    },
    getProductImagePath(ref) {
      try {
        return require(`@/ww25/assets/images/products/${ref}.jpg`);
      } catch (error) {
        console.error(`Product image not found: ${ref}.jpg`, error);
        return '';
      }
    },
    handleProductClick(product) {
      window.dataLayer?.push({
        eventcategory: 'discover product',
        eventaction: 'click',
        eventlabel: product.tracking || product.name + " - " + product.ref,
        event: 'uaevent',
      });
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    pushPageViewToDataLayer() {
      window.dataLayer?.push({
        region: this.region,
        locale: this.langParam,
        site: 'books',
        division: 'wfj',
        page_type: 'plp',
        page_name: this.removeAccents(this.singleChapter.tracking || this.singleChapter.name),
        category: this.removeAccents(this.categoryData.tracking || this.categoryData.slug),
        sub_category_level1 : 'pre event 2025',
        collection: this.removeAccents(this.singleChapter.collection),
        event: 'pageview',
      });
    }
  },
};
</script>