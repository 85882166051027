<template>
  <top-nav />
  <div v-if="categoryData.atrium">
    <div class="atriumBackground">
      <div class="scrollContainer" ref="scrollContainer">
        <svg width="1280" height="1080" viewBox="0 0 1575 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V894.999H325.7L595.742 1073.59H979.175L1249.22 894.999H1574.92V0H0Z" fill="white"/>
          <path d="M325.7 846.741H48.2455V571.191H0V846.741V848.634V894.998H325.7L595.742 1073.59L595.708 1025.34L325.7 846.741Z" fill="#CCCFE5"/>
          <path d="M1526.66 846.741H1249.22L979.209 1025.34L979.175 1073.59L1249.22 894.998H1574.92V848.634V846.741V571.191H1526.66V846.741Z" fill="#CCCFE5"/>
          <path d="M0 48.2569V325.7H48.2455V48.2569H723.785V0H0V48.2569Z" fill="#CCCFE5"/>
          <path d="M851.132 0V48.2569H1526.66V325.7H1574.92V48.2569V0H851.132Z" fill="#CCCFE5"/>
          <path d="M585.754 131.874C503.22 189.093 446.091 280.385 434.745 385.405L479.855 391.54C489.183 299.69 538.842 219.767 610.851 169.765L585.754 131.874ZM1141 385.622C1129.72 280.51 1072.56 189.116 989.962 131.862L964.739 171.692C1035.27 221.808 1083.78 300.864 1093 391.54L1141 385.622Z" fill="#FAFAFA"/>
          <!-- Static strokes -->
          <path d="M816.832 587.076L822.545 613.873" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M753.205 288.994L758.416 313.442" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M845.237 577.817L855.967 601.706" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M719.782 298.71L730.513 322.588" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M870.038 563.415L886.059 585.525" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M691.195 316.486L705.939 336.84" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M892.034 543.563L911.636 561.489" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M666.531 337.263L685.642 354.743" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M919.903 496.515L946.494 505.124" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M630.464 402.748L653.783 410.297" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M921.693 409.374L945.673 401.791" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M628.936 501.932L654.9 493.722" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M892.695 357.595L913.106 339.19" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M666.428 561.682L684.981 544.954" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M870.642 337.434L885.865 316.327" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M691.549 585.765L707 564.351" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M845.853 322.872L856.799 298.367" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M721.242 601.638L731.664 578.319" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M817.619 313.511L823.207 288.23" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M751.574 612.607L757.287 586.723" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M787.88 548.968V615.344" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M787.88 283.145V351.448" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M689.109 450.207H621.775" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M953.975 450.207H886.641" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M702.644 400.298L645.242 367.161" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M932.925 533.255L873.721 499.069" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M702.872 500.507L646.154 533.256" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M933.848 367.161L873.938 401.746" stroke="#CCCFE5" stroke-width="2" stroke-miterlimit="10"/>
          <!-- Highlightable paths (replaces the old 'rects') -->
          <path
            v-for="(path, index) in highlightPaths"
            :key="index"
            class="standPath"
            :d="path.d"
            :class="{ 'highlightedPath': path.highlighted }"
            @click="handlePathClick(index)"
          />
        </svg>
      </div>
    </div>
    <div class="listContainer">
      <div class="listOfButtons">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          @click="handleButtonClick(index); scroll(index)"
          :disabled="button.disabled"
        >
          {{ button.label }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="singleCat?.caliber === 'true'" class="mainContainer">
    <div class="products">
      <div class="caliberTile" v-for="caliber in chaptersContent" :key="caliber.id">
        <div class="caliberImage">
          <img rel="preload" :src="require(`@/assets/images/movements/${caliber.img}.jpg`)"/>
        </div>
        <div class="caliberTitle" v-html="caliber.name"></div>
        <div class="caliberDescription">{{caliber.description}}</div>
        <div class="caliberDescription">
          {{langData.year}}: {{caliber.year}}<br/>
          <span v-if="caliber.hasWatches == true">
            {{langData.watches}}: {{caliber.watches}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mainContainer">
    <div class="chaptersContainer">
      <router-link
        class="chapterTile"
        :class="chapter.shadow"
        v-for="chapter in chapters"
        :key="chapter.id"
        :to="{ name: 'Collection', params: { lang: langParam, categories: categorySlug, chapterName: chapter.slug } }"
        @click="handleCategoryClick(chapter)"
      >
        <img
          :class="chapter.imgPosition"
          :src="getImagePath(chapter.slug)"
        />
        <div :class="chapter.textShadow" class="chapterTitle">
          <div :class="chapter.textColor" v-html="chapter.name"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import topNav from '@/ww25/components/topNav.vue';
import { loadCategoryData } from '@/ww25/utils/dataLoader';

export default {
  props: {
    lang: String,
    categories: String,
  },
  components: {
    topNav,
  },
  data() {
    return {
      langData: {},
      singleChapter: {},
      categoryData: {},
      region: '',
      chapters: [],
      langParam: this.$route.params.lang,
      categorySlug: this.$route.params.categories,
      chaptersContent: [],
      singleCat: {},
      buttons: [
        { label: 'ALL COLLECTIONS', disabled: true, method: 'scrollCenter' },
        { label: 'J12 BLEU', disabled: false, method: 'scrollCenter' },
        { label: 'BLUSH WATCH CAPSULE COLLECTION', disabled: false, method: 'scrollLeft' },
        { label: 'PREMIÈRE GALON', disabled: false, method: 'scrollRight' },
        { label: 'PREMIÈRE ICONIC CHAIN DOUBLE ROW', disabled: false, method: 'scrollRight' },
        { label: '12 CALIBER 12.2 33 MM', disabled: false, method: 'scrollLeft' },
        { label: 'J12 DIAMONDS', disabled: false, method: 'scrollLeft' },
        { label: 'THE LION OF MADEMOISELLE', disabled: false, method: 'scrollRight' },
        { label: 'MADEMOISELLE PRIVÉ BOUTON CAMÉLIA', disabled: false, method: 'scrollRight' },
        { label: 'COCO LONG NECKLACE BLACK SUITE', disabled: false, method: 'scrollRight' },
        { label: 'MONSIEUR', disabled: false, method: 'scrollRight' },
      ],

      /* These are the new highlightable paths that replace <rect> */
      highlightPaths: [
      {
          d:'',
          highlighted: false,
      },
      {
          d:'M1070.89 1073.57L1261.21 947.893L1210.93 871.767L1020.62 997.449L1070.89 1073.57Z',
          highlighted: false,
      },
      {
          d:'M1479.01 695.747H1399.19V775.567H1479.01V695.747Z',
          highlighted: false,
      },
      {
          d:'M1305.84 614.256L1227.23 600.399L1213.37 679.006L1291.98 692.863L1305.84 614.256Z',
          highlighted: false,
      },
      {
          d:'M1305.82 410.3H1226V490.12H1305.82V410.3Z',
          highlighted: false,
      },
      {
          d:'M1479.01 131.306H1399.19V211.126H1479.01V131.306Z',
          highlighted: false,
      },
      {
          d:'M1291.93 204.823L1213.32 218.68L1227.18 297.286L1305.79 283.43L1291.93 204.823Z',
          highlighted: false,
      },
      {
          d:'M369.61 218.576L291.004 204.719L277.147 283.326L355.754 297.182L369.61 218.576Z',
          highlighted: false,
      },
      {
          d:'M183.643 131.306H103.823V211.126H183.643V131.306Z',
          highlighted: false,
      },
      {
          d:'M357.348 410.3H277.528V490.12H357.348V410.3Z',
          highlighted: false,
      },
      {
          d:'M183.643 695.747H103.823V775.567H183.643V695.747Z',
          highlighted: false,
      },
      {
          d:'M356.18 603.231L277.574 617.088L291.43 695.695L370.037 681.838L356.18 603.231Z',
          highlighted: false,
      },
      {
          d:'M563.345 1003.88L373.031 878.193L322.758 954.319L513.072 1080L563.345 1003.88Z',
          highlighted: false,
      },
      {
          d:'M677.09 585.583C638.035 553.347 613.143 504.566 613.143 449.98C613.143 395.383 638.035 346.602 677.09 314.377L651.354 283.156C603.302 322.804 572.686 382.817 572.686 449.98C572.686 516.47 602.709 575.959 649.929 615.618L677.09 585.583ZM928.136 615.344C975.184 575.685 1005.07 516.322 1005.07 449.969C1005.07 383.889 975.423 324.72 928.694 285.072L900.666 314.366C939.721 346.602 964.613 395.383 964.613 449.969C964.613 504.566 939.721 553.347 900.666 585.572L928.136 615.344Z',
          highlighted: false,
      },
      ],
    };
  },
  async created() {
    await this.getDetails();
    this.scrollToTop();
    this.pushPageViewToDataLayer();
  },
  methods: {
    async getDetails() {
      const categoryData = await loadCategoryData(this.langParam);
      this.langData = categoryData;
      this.region = categoryData.region || '';
      const atriumCategory = categoryData.categories.find(
        (category) => category.slug === 'atrium'
      );
      
      // Find the current category
      const currentCategory = categoryData.categories.find(
        (category) => category.slug === this.categorySlug
      );
      
      if (this.categorySlug === 'atrium' && atriumCategory) {
        this.categoryData = { atrium: true, ...atriumCategory };
        this.singleCat = atriumCategory;
      } else if (currentCategory) {
        this.categoryData = currentCategory;
        this.singleCat = currentCategory;
      } else {
        console.error(`Category ${this.categorySlug} not found.`);
      }

      if (this.categoryData) {
        this.chapters = this.categoryData.chapters || [];
      }

      if (this.singleCat.slug === "in-house-calibers") {
        this.chaptersContent = this.singleCat.calibers?.filter(element => element.id) || [];
      }
      console.log(this.singleCat.slug)
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getImagePath(img) {
      return require(`@/ww25/assets/images/chapters/${img}.jpg`);
    },
    handleButtonClick(index) {
      // Re-enable all buttons
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].disabled = false;
      }
      // Disable the clicked one
      this.buttons[index].disabled = true;

      // Clear highlights
      for (let i = 0; i < this.highlightPaths.length; i++) {
        this.highlightPaths[i].highlighted = false;
      }

      if (index === 0) {
        this.highlightMultiplePaths([]);
      }
      if (index === 1) {
        this.highlightMultiplePaths([13]);
      }
      if (index === 2) {
        this.highlightMultiplePaths([7,8,9,10,11]);
      }
      if (index === 3) {
        this.highlightMultiplePaths([6]);
      }
      if (index === 4) {
        this.highlightMultiplePaths([5]);
      }
      if (index === 5) {
        this.highlightMultiplePaths([12]);
      }
      if (index === 6) {
        this.highlightMultiplePaths([12]);
      }
      if (index === 7) {
        this.highlightMultiplePaths([1,2,3,4]);
      }
      if (index === 8) {
        this.highlightMultiplePaths([2]);
      }
      if (index === 9) {
        this.highlightMultiplePaths([2]);
      }
      if (index === 10) {
        this.highlightMultiplePaths([3]);
      }
      // etc. (You can customize the logic to map each button to whichever shape indexes you want)
    },
    highlightMultiplePaths(indexes) {
      indexes.forEach((i) => {
        if (this.highlightPaths[i]) {
          this.highlightPaths[i].highlighted = true;
        }
      });
    },
    handlePathClick(index) {
      // Example navigation: change to a different route or do something else
      this.$router.push({
        name: 'Collection',
        params: {
          chapterName: index,
        },
      });
    },
    scroll(index) {
      const method = this.buttons[index].method;
      this[method]();
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollLeft = 120;
    },
    scrollCenter() {
      setTimeout(() => {
        this.$refs.scrollContainer.scrollLeft =
          this.$refs.scrollContainer.scrollWidth / 2.85;
      });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollLeft =
        this.$refs.scrollContainer.scrollWidth - 500;
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    pushPageViewToDataLayer() {
      window.dataLayer?.push({
        region: this.region,
        locale: this.langParam,
        site: 'books',
        division: 'wfj',
        page_type: 'categories',
        page_name: this.removeAccents(
          this.categoryData.tracking ||
            this.categoryData.slug + ' all collections'
        ),
        category: this.removeAccents(
          this.categoryData.tracking || this.categoryData.slug
        ),
        sub_category_level1: 'pre event 2025',
        event: 'pageview',
      });
    },
    handleCategoryClick(chapter) {
      window.dataLayer?.push({
        eventcategory: 'discover collection',
        eventaction: 'click',
        eventlabel: this.removeAccents(chapter.tracking || chapter.name),
        event: 'uaevent',
      });
    },
  },
};
</script>

<style scoped>
.standPath {
  fill: #D8D8D8; /* Default color */
  cursor: pointer;
  transition: fill 0.2s ease;
}

/* Highlight style for the #D8D8D8 paths */
.standPath.highlightedPath {
  fill: #071848 !important; /* Example highlight color */
}
</style>