<template>
  <header class="pdpHeader">
    <div class="nav-left">
      <router-link v-if="($route.name === 'SingleProduct')" :to="{ name: 'Collection'}" @click="handleBackButtonClick">
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.52 14.6667L0 7.33333L7.52 0L8 0.473333L0.966667 7.33333L8 14.1933L7.52 14.6667Z" fill="#767676"/>
        </svg>
      </router-link>
      <router-link v-else :to="{ name: 'SingleProduct'}" @click="handleBackButtonClick">
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.52 14.6667L0 7.33333L7.52 0L8 0.473333L0.966667 7.33333L8 14.1933L7.52 14.6667Z" fill="#767676"/>
        </svg>
      </router-link>
    </div>
    <div class="nav-center">
        <router-link to="/">
        </router-link>
    </div>
    <div class="nav-right-legend">
      <router-link v-if="($route.name != 'Legends')" :to="{ name: 'Legends'}" @click="handleLegendButtonClick">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4441C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51982 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM15 10.2C15 11.2 14.39 11.74 13.43 12.25C12.92 12.52 12.52 12.78 12.5 13.5H11.2C11.1471 13.052 11.2427 12.599 11.4721 12.2106C11.7015 11.8222 12.0521 11.5199 12.47 11.35C13.18 10.97 13.62 10.75 13.62 10.2C13.62 9.65 13.2 9.32 12.13 9.32C11.7169 9.26951 11.2997 9.37322 10.9583 9.61126C10.617 9.84929 10.3754 10.2049 10.28 10.61L9 10.26C9.16177 9.56342 9.57187 8.94969 10.1535 8.53369C10.7352 8.11769 11.4485 7.92795 12.16 8C13.44 8 15 8.54 15 10.2ZM11 14.29H12.59V16H11V14.29Z" fill="#767676"/>
        </svg>
      </router-link>
      <router-link :to="'/' + this.$route.params.lang + '/ww-atrium'" v-if="this.$route.params.categories === 'ww-atrium'">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.8571" cy="11.5" r="9.35714" stroke="#767676"/>
        <path d="M10.3575 9.43222L7.0368 15.6981L13.4986 13.1756L16.7902 7.51404L10.3575 9.43222Z" fill="#767676"/>
        <circle cx="11.8571" cy="11.4999" r="0.5" stroke="white" stroke-width="0.642857"/>
        </svg>
      </router-link>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      langParam: this.$route.params.lang,
      categoryData: {},
      region: '',
      categorySlug: this.$route.params.categories,
      allLegends: [],
      singleChapter: null,
      singleProduct: null,
      productRef: this.$route.params.productRef,
    };
  },
  methods: {
    async initializeData() {
      try {
        const categoriesDataModule = await import(
          /* webpackChunkName: "categories-data-[request]" */
          `@/ww25/data/${this.langParam}/categories.json`
        );
        const categoriesData = categoriesDataModule.default || categoriesDataModule;
        this.region = categoriesData.region || '';


        // Access the categories array
        const categoriesArray = categoriesData.categories;

        if (Array.isArray(categoriesArray)) {
          this.categoryData = categoriesArray.find(
            (category) => category.slug === this.categorySlug
          );

          if (this.categoryData) {
            this.allLegends = this.categoryData.legends || [];
            this.singleChapter = this.categoryData.chapters?.[0] || null;
            this.singleProduct = this.singleChapter?.products?.[0] || null;
          } else {
            console.error(
              `Category '${this.categorySlug}' not found in categories.json for language '${this.langParam}'.`
            );
          }
        } else {
          console.error('categoriesData.categories is not an array.');
        }
      } catch (error) {
        console.error('Error loading categories.json:', error);
      }
    },
    handleBackButtonClick() {
      window.dataLayer?.push({
        eventcategory: 'back',
        eventaction: 'click',
        eventlabel: this.$route.name,
        event: 'uaevent',
      });
    },
    handleLegendButtonClick() {
      window.dataLayer?.push({
        eventcategory: 'see legend',
        eventaction: 'click',
        eventlabel: this.productRef || 'unknown', // Use productRef from route params
        event: 'uaevent',
      });
    },
  },
};
</script>